import { useState } from "react";
import Box from "@mui/material/Box";
import { ShoppingCartProvider } from "../../context/ShoppingCartContext";
import Header from "../../features/header";
import CrewOverview from "../crew-overview";
import Cockpit from "../cockpit";
import Confirm from "../../components/Confirm";
import ConfirmContext from "../../context/ConfirmContext";
import useCrew from "../../hooks/useCrew";
import DisplayContext from "../../context/DisplayContext";

import "./index.scss";

const Dashboard = () => {
  const [showCockpit, setshowCockpit] = useState(true);
  function toggleCockpit(){
    setshowCockpit(!showCockpit);
  }

  const [confirmValues, setConfirmValues] = useState(null);
  const { crew } = useCrew();
  const [fullscreen, setFullscreen] = useState(false);

  const confirmActions = {
    show(title, message, onSuccess) {
      setConfirmValues({
        title,
        message,
        onConfirm(isSuccess) {
          if (isSuccess) {
            onSuccess();
          }
          setConfirmValues(null);
        },
      });
    },
    notification(title, message) {
      setConfirmValues({
        title,
        message,
        notification: true,
        onConfirm() {
          setConfirmValues(null);
        },
      });
    },
  };

  const toggleFullscreen = () => setFullscreen(!fullscreen);

  const [menuOpen,setMenuOpen] = useState(false);

  return (
    <>
      <ConfirmContext.Provider value={confirmActions}>
        <ShoppingCartProvider>
          <DisplayContext.Provider value={{ fullscreen, toggleFullscreen }}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"stretch"}
              style={{ height: "100vh" }}
              className={`${fullscreen && "fullscreen"}`}
            >
              <Box className={`header-wrapper ${fullscreen && "fullscreen"}`}>
                <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
              </Box>
              <div style={{display: showCockpit?"block":"none"}}>
                  <Cockpit showCockpit = {showCockpit} triggershowCockpit = {toggleCockpit} crew = {crew}/> 
              </div>
              <div style={{display: !showCockpit?"block":"none"}}>
                <CrewOverview menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
              </div>              
            </Box>
          </DisplayContext.Provider>
        </ShoppingCartProvider>
      </ConfirmContext.Provider>
      {confirmValues && (
        <Confirm
          title={confirmValues.title}
          onConfirm={confirmValues.onConfirm}
          notification={confirmValues.notification}
        >
          {confirmValues.message}
        </Confirm>
      )}
    </>
  );
};

export default Dashboard;