import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router";

import Box from "@mui/material/Box";

import Operations from "../../features/operations";
import Positions from "../../features/positions";
import UserNavigation from "../../features/user-navigation";
import CrewCourses, { BundleCourses } from "../../features/crew-courses";
import Aircraft from "../../features/aircraft";
import Specializations from "../../features/specializations";
import UserTitle from "../../components/UserTitle";
import CourseHistory from "../../features/course-history";
import CertificateActions from "../../context/CertificateActions";

import CurrentUserCrewContext from "../../context/CurrentUserCrewContext";
import useUser from "../../hooks/useUser";
import useCrew from "../../hooks/useCrew";

import { getAllCourses } from "../../api/courses";
import { getUserBoughtCourses } from "../../api/payment";
import {
  getUserCertificates,
  getUserProperties,
  getUserAircraft,
} from "../../api/user";
import { getTrainingPurchaseCandidates } from "../../api/training";
import { addCoursesHierarchy } from "../../services/trainingHierarchy";
import { useTranslation } from "react-i18next";




// TODO: merge with members-page, the difference in useUser or match from route
const UserPage = ({ match }) => {

    //
  const { t } = useTranslation();
  const myPositionCourses = t('my_position_courses')
  const myOperationsCourses = t('my_operations_courses')
  const myAircraftCourses = t('my_aircraft_courses')
  const mySpecializationsCourses = t('my_specializations_courses')

  const HEADER_CAPTIONS = {
    positions: myPositionCourses,
    operations: myOperationsCourses,
    aircraft: myAircraftCourses,
    specializations: mySpecializationsCourses,
    all: "All Company Courses",
  };


  const [historyItem, setHistoryItem] = useState(null);
  const [certificates, setUserCertificates] = useState(null);
  const [courses, setCourses] = useState(null);
  const [properties, setProperties] = useState(null);
  const [aircraft, setAircraft] = useState(null);
  const [boughtCourses, setBoughtCourses] = useState(null);
  const user = useUser();
  const { crew } = useCrew();

  useEffect(() => {
    (async () => {
      const [
        courses,
        userAircraft,
        boughtCourses,
        properties,
        userCertificates,
        trainingPurchaseCandidates
      ] = await Promise.all([
        getAllCourses(crew.id),
        getUserAircraft(crew.id),
        getUserBoughtCourses(user.id, crew.id),
        getUserProperties(crew.id, user.id),
        getUserCertificates(user.id, crew.id),
        getTrainingPurchaseCandidates(user.id, crew.id)
      ]);

      setAircraft(userAircraft);
      setBoughtCourses(boughtCourses);
      setProperties(properties);
      setUserCertificates(userCertificates);

      // Update courses with course highest in the training hierarchy
      const updatedCourses = addCoursesHierarchy(courses, trainingPurchaseCandidates);
      setCourses(updatedCourses);
    })();
  }, [user, crew]);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Route
          path={`${match.path}/:pageId`}
          children={({ match }) => (
            <h2>{HEADER_CAPTIONS[match.params?.pageId] || ""}</h2>
          )}
        />
        <UserTitle id={`${user.id}`} name={user.name} email={user.email} />
      </Box>
      <Box marginBottom={"1.5rem"}>
        <Route path={`${match.path}/:pageId`} component={UserNavigation} />
      </Box>
      {courses && certificates && properties && aircraft ? (
        <Box style={{ overflow: "auto" }}>
          <CertificateActions.Provider
            value={{
              showHistory(certificate) {
                setHistoryItem(certificate);
              },
              getBoughtCourses() {
                return boughtCourses;
              },
            }}
          >
            <CurrentUserCrewContext.Provider
              value={{ userId: user.id, crewId: crew.id }}
            >
              <Switch>
                <Route path={`${match.path}/positions`}>
                  <Positions
                    userId={user.id}
                    crewId={crew.id}
                    certificates={certificates}
                    courses={courses}
                    properties={properties}
                  />
                </Route>
                <Route path={`${match.path}/operations`}>
                  <Operations
                    userId={user.id}
                    crewId={crew.id}
                    certificates={certificates}
                    courses={courses}
                    properties={properties}
                  />
                </Route>
                <Route path={`${match.path}/aircraft`}>
                  <Aircraft
                    userId={user.id}
                    crewId={crew.id}
                    certificates={certificates}
                    courses={courses}
                    properties={properties}
                    aircraft={aircraft}
                  />
                </Route>
                <Route path={`${match.path}/specializations`}>
                  <Specializations
                    userId={user.id}
                    crewId={crew.id}
                    certificates={certificates}
                    courses={courses}
                    properties={properties}
                    aircraft={aircraft}
                  />
                </Route>
                <Route path={`${match.path}/all`}>
                  <CrewCourses
                    userId={user.id}
                    crewId={crew.id}
                    certificates={certificates}
                    courses={courses}
                    properties={properties}
                  />
                </Route>
                <Route path={`${match.path}/bundles`}>
                  <BundleCourses
                    crewId={crew.id}
                    certificates={certificates}
                    courses={courses}
                    properties={properties}
                  />
                </Route>
              </Switch>
            </CurrentUserCrewContext.Provider>
          </CertificateActions.Provider>
        </Box>
      ) : null}
      {historyItem ? (
        <CourseHistory
          userId={user.id}
          certificate={historyItem}
          userCertificates={certificates}
          courses={courses}
          onClose={() => setHistoryItem(null)}
        />
      ) : null}
    </>
  );
};

export default UserPage;
