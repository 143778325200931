// TODO: move this to training API service once it will be converted to TS
export interface TrainingPurchaseCandidate {
    course_id: number;
    highest_hierarchy_course_id: number;
    highest_hierarchy_course_name: string;
    highest_hierarchy_training_id: number;
}

// TODO: replace any once courses are typed
export const addCoursesHierarchy = (courses: { courses: any[], [key: string]: any; }, trainingPurchaseCandidates: TrainingPurchaseCandidate[]) => ({
    ...courses,
    courses: courses.courses.map(course => {
        const matchingCourse = trainingPurchaseCandidates.find(candidate => candidate.course_id === course.id);
        if (matchingCourse) {
            return {
                ...course,
                highestInTrainingHierarchyCourseId: matchingCourse.highest_hierarchy_course_id,
                highestInTrainingHierarchyCourseName: matchingCourse.highest_hierarchy_course_name,
                highestInTrainingHierarchyTrainingId: matchingCourse.highest_hierarchy_training_id,
            };
        }
        return course;
    })
});