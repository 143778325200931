import request from "./request";
import CourseService from "../services/course";

const training2Request = (training, courses, crews, trainers, instructors, assessors, checkers, elearningPassConditions, classroomPassConditions, practicalPassConditions) => ({
  displayname: training.displayname,
  courseId: training.courseId,
  context: training.contextTraining,
  classification: training.classification.join("; "),
  positions: training.positions.join("; "),
  status: training.status,
  trainingDate: training.trainingDate,
  trainingStartDate: training.trainingStartDate,
  trainingEndDate: training.trainingEndDate,
  trainingSlot: training.trainingSlot,
  classroom: training.classroom,
  location: training.location,
	virtualClassroomLink: training.virtualClassroomLink,
	classroomResourceLink: training.classroomResourceLink,
	onlineTestLink: training.onlineTestLink,
  isELearning: training.isELearning,
  isClassroom: training.isClassroom,
  isPractical: training.isPractical,
  practicalTraining: training.practicalTraining,
  eLearningCheck: training.eLearningCheck,
  eLearningMoodle: training.eLearningMoodle,
  classroomMoodle: training.classroomMoodle,
  classroomAttendance: training.classroomAttendance,
  classroomCheck: training.classroomCheck,
  writtenTest: training.writtenTest,
  instructorComplete: training.instructorComplete,
  assessorPassed: training.assessorPassed,
  checkerPassed: training.checkerPassed,
  courses,
  crews,
  trainers,
  instructors,
  assessors,
  checkers,
  elearningPassConditions, 
  classroomPassConditions, 
  practicalPassConditions
});


//


export const getTrainings = async (params = {}) => {
  const query = [];
  if (params.activeOnly) {
    query.push(`enabled=true`);
  }
  const trainings  = await request(`/trainings/?${query.join("&")}`);
  return trainings;
};

export const getAllTrainingsWithEvents = async (params = {}) => {
  const trainings  = await request(`/trainings/events`);
  return trainings;
};


export const getTrainingsInitial = async (params = {}) => {
  const trainings  = await request(`/trainings/initial`);
  return trainings;
};



export const getTrainingDependencies = async (params = {}) => {
  const trainingsDependecies  = await request(`/trainings/dependecies`);
  let result = trainingsDependecies.reduce((acc, item) => {
    if (!acc[item.training_id]) {
      acc[item.training_id] = [];
    }
    acc[item.training_id].push(item.dependecies_id);
    return acc;
  }, {});
  return result;
};


export const setNewDependencies = async (training, dependencies) => {
  const url = `/trainings/dependecies`;
  const json = await request.post(url, {
    training: training,
    dependencies: dependencies,
  });

  return json;
};

export const deleteDependenciesFromTraining = async (trainingId) => {
  const json = await request(`/trainings/dependecies/${trainingId}`, "DELETE");
  return json;
};

export const createTraining = async (training, courses, crews, trainers , instructors,  assessors, checkers) => {

  const response = await request.post(
    `/trainings`,
    training2Request(training, courses, crews, trainers, instructors, assessors, checkers)
  );
  return response;
};


export const getTraining = async (trainingId) => {
  const training = await request(`/trainings/${trainingId}`);
  return training;
};


export const getCrewTrainings = async (crewId) => {
  const trainings = await request(`/trainings/crew/${crewId}`);
  return trainings;
};

export const getCrewUsersTrainings = async (crewId) => {
  const users = await request(`/trainings/users-crew/${crewId}`);
  return users;
};


export const getTrainingByCourseId = async (courseId) => {
  const training = await request(`/trainings/byCourseId/${courseId}`);
  return training;
};






export const getTrainingDetails = async (trainingId) => {
  const training = await request(`/trainings/details/${trainingId}`);
  
  return training;
};


export const updateTraining = async (
  trainingId, 
  training,
  courses,
  crews,
  trainers,
  instructors,
  assessors, 
  checkers, 
) => {
  const response = await request.put(
    `/trainings/${trainingId}`,
    training2Request(training, courses, crews,trainers, instructors, assessors, checkers)
  );
  return response;
};

export const deleteTrainingCourseById = async (trainingId, trainingCourseId) => {
  const json = await request(`/trainings/${trainingId}/${trainingCourseId}`, "DELETE");
  return json;
};






export const changeTrainingIsOnSite = async (trainingId, isOnSite) => {
  const response = await request.put(`/trainings/onsite/${trainingId}`, {
    isOnSite: isOnSite ? 1 : 0,
  });
  return response; 
};

export const changeTrainingIsPracticalAssessment = async (trainingId, isPracticalAssessment) => {
  const response = await request.put(`/trainings/practical/${trainingId}`, {
    isPracticalAssessment: isPracticalAssessment ? 1 : 0,
  });
  return response;
};

export const updateCheckingProgress = async (id, field, value, evaluatorId) => {
  const response = await request.put(`/users-training/checking-progress/${id}`, {
    field,
    value,
    evaluatorId
  });
  return response;
};

export const updateModuleStatus = async (id, field, value) => {
  const response = await request.put(`/users-training/module-status/${id}`, {
    field,
    value
  });
  return response;
};




export const updateStatusCertificate = async (userId, courseId, completed, validPeriod) => {
  const response = await request.put(`/users-training/update-status-certificate`, {
    userId, 
    courseId,
    completed,
    validPeriod
  });
  return response;
};




export const updateClassroomModule = async (id, classroomAttendance,classroomTest) => {
  const response = await request.put(`/users-training/checking-progress/${id}`, {
    field,
    value
  });
  return response;
};

export const getCrewTrainingBought = async (trainingId, crewId) => {   
  const usersCrewTraining = await request(`/users-training/bought/${trainingId}/${crewId}`);   
  return usersCrewTraining;
};


//


export const getUsersInfoTraining = async (trainingId, crewId, trainingRole) => {  
  const usersInfoTraining = await request(`/users-training/info/${trainingId}/${crewId}/${trainingRole}`); 

  return usersInfoTraining;
};

export const getUsersTraining = async (trainingId, crewId, trainingRole) => {  
  //const usersTraining = await request(`/users-training/${trainingId}/${trainingRole}`);  
  let usersTraining
  if (trainingId == 'all') {
    usersTraining = await request(`/users-training/${trainingRole}`);   
  } else {
     usersTraining = await request(`/users-training/byId/${trainingId}/${crewId}/${trainingRole}`); 
  }
  //const usersTraining = await request(`/users-training/${trainingRole}`);   
  return usersTraining;
};





export const getAllCrewTrainings = async () => {   
  console.log('userId')
  const usersCrewTraining = await request(`/trainings/events`);
  console.log('usersCrewTraininssg', usersCrewTraining) 
  return usersCrewTraining;
};

export const getTrainingPurchaseCandidates = async (userId, crewId) => {
  const res = await request(`/trainings/purchase_candidates/crew/${crewId}/user/${userId}`);
  return res;
};

export const getUserCrewTrainings = async (trainingRole, userId, crewId) => {   
  const usersCrewTraining = await request(`/users-training/crew/${trainingRole}/${userId}/${crewId}`);   
  return usersCrewTraining;
};


export const getAllUserTrainings = async (trainingRole) => {   
  const usersCrewTraining = await request(`/users-training/all/${trainingRole}`);   
  return usersCrewTraining;
}; 




export const getAllTrainings = async (trainingRole) => {   
  const allTraining = await request(`/users-training/${trainingRole}`);   
  return allTraining;
};



export const deleteWrittenTest = async ( writtenTestId) => {
  const response = await request.delete(`/users-training/inscribed-written-test/${writtenTestId}`);
  return response;
};

export const openExportWrittenTest = async (fileName) => {
  const ssid = await getSSID();
  let url = `${process.env.REACT_APP_API_URL}/files/${fileName}/export/${courseId}?ssid=${ssid}`;
  window.open(url, "_blank");
};


export const getUsersAssessment = async (trainingId, crewId) => {
  const res = await request(`/users-training/assessment/${trainingId}/${crewId}`); 
  const retVal = [];
  res.forEach(item => {
    if (item.User.UserProperties.length>0) {
      item.User.UserProperties.forEach ( elem => {
        if (elem.Position){
          retVal.push({
            id:item.id,
            fullname: item.User.fullname,
            companyName: item.Crew.company_name,
            positionId:elem.Position.id,
            positionName:elem.Position.name
          })
        }

      })
    } else {
    retVal.push({
      id:item.id,
      fullname: item.User.fullname,
      companyName: item.Crew.company_name,

      positionId:null,
      positionName:null
    })
    };
  });
  const uniqueRetVal = retVal.filter((record, index) => {
    return index === retVal.findIndex((obj) => {
      return JSON.stringify(obj) === JSON.stringify(record);
    });
  });
  return uniqueRetVal;
}



export const getUsersAttemps = async (trainingId,usersInscribed) => {
  const usersWrittenTest = await request(`/users-training/user-written-test/${trainingId}`); 
  let res = [];
  usersInscribed.forEach(user => {
    res.push({
        userId : user.user_id,
        fullname : user.User.fullname, 
        attemps : usersWrittenTest.filter( elem => elem.user_id === user.user_id).length, 
        passed : usersWrittenTest.filter( elem => (elem.user_id === user.user_id) && (elem.valid_answer >= process.env.REACT_APP_WRITTEN_TEST_PASSED)).length, 
      });    
  });
  return res;
}


export const getUsersWritteTest = async (trainingId,usersInscribed) => {
  const usersWrittenTest = await request(`/users-training/user-written-test/${trainingId}`);   

  let res = [];
  usersInscribed.forEach(user => {
    const userWrittenTest = usersWrittenTest.find( elem =>  elem.user_id == user.user_id);
    let writtenTestId = null;
    let trainingIdWrittenTest = null;
    let validAnswer = null;
    let fileName = null;
    let completeDate = null;
    let comment = null;      
    if ( userWrittenTest){
      writtenTestId= userWrittenTest.id
      trainingIdWrittenTest = userWrittenTest.training_id
      validAnswer= userWrittenTest.valid_answer
      fileName= userWrittenTest.file_name
      completeDate=  (new Date(userWrittenTest.completed_date)).toLocaleDateString('de-CH')
      comment= userWrittenTest.comment
    }

    res.push({
      userId : user.user_id,
      fullname : user.User.fullname, 
      trainingIdWrittenTest,
      writtenTestId,
      validAnswer,
      fileName,
      completeDate,
      comment
    });

  });

  return res;
};

export const getUsersInscribed = async (trainingId, crewId) => {
  const usersInscribed = await request(`/users-training/inscribed/${trainingId}/${crewId}`);  
  usersInscribed.map( el => {
    el,
    el.fullname = el.User.fullname
    el.companyName = el.Crew.company_name,
    el.instructorComplete = el.instructor_complete === 1 ? "Yes" : "No",
    el.assessoPassed =  el.assessor_passed  === 1 ? "Yes" : "No"
  }) 
  return usersInscribed;
};


export const getUsersPassCondition = async (trainingId, passConditionId) => {
  const usersInscribed = await request(`/users-training/users-pass-condition/${trainingId}/${passConditionId}`);   
  return usersInscribed;
};


export const getPassConditionTrainingId = async (trainingId, passConditionId) => {
  const usersInscribed = await request(`/users-training/pass-condition-training-id/${trainingId}/${passConditionId}`);   
  return usersInscribed;
};



export const getUsersTrainingByTrainingId = async (trainingId) => {
  const usersTraining = await request(`/users-training/to-inscribe/${trainingId}`);   
  return usersTraining;
};



export const inscribeOrUniscribeUserToCourse = async ( inscribeId, trainingId, userId, crewId, courseId,  trainingRole, validPeriod) => {
  let response;
  if (inscribeId){
    response = await request.delete(`/users-training/${inscribeId}/${userId}/${courseId}`);
  } else {
    response = await request.post(`/users-training`,{trainingId, userId, crewId, courseId, trainingRole, validPeriod});
  }
  return response;
};

//ENUM('user', 'trainer', 'instructor', 'assessor', 'checker')
/* 
export const getObervableByOperationId = async (operationId) => {
  const json = await request(`/users-training/observable/${operationId}`);
  return json;
};
 */
export const getObervableByPositionId = async (positionId) => {
  const json = await request(`/users-training/observable/${positionId}`);
  return json;
};


export const getUserToTrainingCourse = async (trainingId,userId, crewId) => {
  const training = await request(`/users-training/exist/${trainingId}/${userId}/${crewId}`);
  return training;
};



export const addUserToTrainingCourse = async (trainingId, userId, crewId, courseId,  trainingRole, validPeriod) => {
  let response;

  response = await request.post(`/users-training`,{trainingId, userId, crewId, courseId, trainingRole, validPeriod});
  return response;
};



export const changeStatusAnswer = async (trainingUserId,  processStepId, activitiesTrainingId,  observableId, passed) => {
    const response = await request.post(`/users-training/change-status-answer/`,{trainingUserId, processStepId, activitiesTrainingId,  observableId, passed});
    return response
}


export const setAssessmentPassed = async (trainingUserId, status) => {
  const response = await request.put(`/users-training/set-assessment/${trainingUserId}`,{status});
  return response
}

export const setPracticalStatus = async (trainingUserId, status) => {
  const response = await request.put(`/users-training/set-practical-status/${trainingUserId}`,{status});
  return response
}




export const getAssessmentPassed = async (trainingUserId) => {
  const json = await request(`/users-training/assessment-passed/${trainingUserId}`);
  return json;
};




export const getAttendanceUserToSlot = async (trainingId,slotId) => {
  const usersTraining = await request(`/users-training/attendance/${trainingId}/${slotId}`);   
  return usersTraining;
};


export const addAttendanceUserToSlot = async ( attendanceId, slotId, userId) => {
  let response;
  if (attendanceId){
    response = await request.delete(`/users-training/attendance/${attendanceId}`);
  } else {
    response = await request.post(`/users-training/attendance/`,{slotId, userId});
  }
  return response;
};




export const getProgressPassCondition = async ( trainingId, userId ) => {
  const res = await request(`/users-training/progress/${trainingId}/${userId}`);
  const passed = res.userPassCondition.map (el => el.pass_condition_training_id);
  const passConditionList = res.traininingPassCondition.map( item => {
    let status = false;
    let passConditionTrainingUserId = null;
    if (passed.includes(item.id)) {
      status=true;
      passConditionTrainingUserId = res.userPassCondition.filter( elem => elem.pass_condition_training_id === item.id)[0].id;
    } 
    return ({
      id: item.id,
      module: item.module,
      passConditionName: item.PassCondition.name,
      passConditionType: item.PassCondition.type,
      passConditionValue: item.PassCondition.value,
      passConditionTrainingId: item.pass_condition_id,
      status,
      passConditionTrainingUserId,
      userId
    })
   });
  return passConditionList;
};

export const getTrainingModuleStatus = async ( trainingId, userId ) => {
  const usersModuleProgress = await request(`/users-training/module-progress/${trainingId}/${userId}`);
  return usersModuleProgress; 
};

export const getTrainingUser = async ( trainingUserId ) => {
  const usersTrainingProgress = await request(`/users-training/training-user-progress/${trainingUserId}`);
  return usersTrainingProgress; 
};



export const getUserCertificate = async ( courseId, userId ) => {
  const userCertificate = await request(`/users-training/certificate/${courseId}/${userId}`);
  return userCertificate;
};


export const addPassConditionTrainingUser = async ( passConditionTrainingUserId, userId, passConditionTrainingId) => {
  let response;
  if (passConditionTrainingUserId){
    response = await request.delete(`/users-training/progress/${passConditionTrainingUserId}`);
  } else {
    response = await request.post(`/users-training/progress`, {userId, passConditionTrainingId});
  }
  return response;
};


export const updateTrainingModuleStatus = async (trainingId, userId, eLearningStatus , classroomStatus, practicalStatus)  => {
  const response = await request.put(`/users-training/progress/${trainingId}/${userId}`, {
    eLearningStatus,
    classroomStatus,
    practicalStatus
  });
  return response;
};

export const updateCertificate = async (courseId, userId, completed, validPeriod)  => {
  const response = await request.put(`/users-training/certificate/${courseId}/${userId}`, {
    completed,
    validPeriod
  });
  return response;
};



export const uploadWrittenTest = async ({
  trainingId,
  userId,
  validAnswer,
  files,
  completedDate,
  comment,
}) => {
  const formData = new FormData();
  for (const file of files) {
    formData.append("file", file);
  }
  validAnswer && formData.append("validAnswer", validAnswer);
  completedDate && formData.append("completedDate", completedDate.format("YYYY-MM-DD"));
  comment && formData.append("comment", comment);
  userId && formData.append("userId", userId);

  const status = await request(
    `/users-training/written-test/${trainingId}`,
    "POST",
    formData
  );

  return status;
};

export const updatePassConditionEventTypeComplete = async (id, body) => {
  const response = await request(`/users-training/pass-condition-event-type-complete/${id}`, "PUT", body);
  return response;
};

export const getScheduleSlots = async (trainingId, userId) => {
  const response =  await request(`/users-training/schedule-slots/${trainingId}/${userId}`);
  return response;
};


export const getCheckingProgress = async (trainingId, userId) => {
  const response =  await request(`/users-training/checking-progress/${trainingId}/${userId}`);
  return response;
};






