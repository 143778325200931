import React, { useEffect, useState } from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import TextField from "@mui/material/TextField";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link, useHistory } from "react-router-dom";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import Button from "../../components/Button";
import Logo from "../../components/Logo";
import UserMenu from "../user-menu";
import useCrew from "../../hooks/useCrew";
import useUser from "../../hooks/useUser";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Hamburger from '../../assets/icon/hamburgur.png';
import Mobile_Logo from '../../assets/icon/logo.png';
import User_Icon from '../../assets/icon/profile.png';
import Search_Icon from '../../assets/icon/search.png';
import "./index.scss";

const StyledSearch = styled(TextField)({
    width: "100%",
    "& input": {
      padding: "1rem 1.2rem",
    },
});

const StyledList = styled(List)({
    maxHeight: "80vh",
    overflowY: "auto",
});

const SelectMenu = ({ search = true, optionArray, onSelect }) => {
  const [searchKey, setSearchKey] = useState("");
  const searchedOptions = optionArray.filter((item) =>
    item?.name?.toLowerCase().includes(searchKey.toLowerCase()) 
  );
  const { t } = useTranslation();
  return (
    <>
      {search ? (
        <StyledSearch
          placeholder={t('search')}
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          variant="standard"
          autoFocus
        />
      ) : null}
      <StyledList className="profile-menu">
        {searchedOptions.map((option, index) => (
          <ListItemButton
            key={index}
            className="option-list-item"
            onClick={() => {
              setSearchKey("");
              onSelect(option);
            }}
          >
            {option.name} ({option.id})
          </ListItemButton>
        ))}
      </StyledList>
    </>
  );
};

const settingsOption = [
  {
    name: "Profile",
    link: "/profile/edit",
  },
];

const Header = ({ menuOpen, setMenuOpen}) => {
  const { t } = useTranslation();

  const { crew, setCrew } = useCrew();
  const user = useUser();
  const [isCrewMenuOpen, setIsCrewMenuOpen] = useState(false);
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
  const [hasShoppingItems, setHasShoppingItems] = useState(false);
  const history = useHistory();
  const { items: shoppingCartItems } = useShoppingCart();
  const [lang, setLang] = React.useState('en');



  useEffect(() => {
    setHasShoppingItems(shoppingCartItems?.length > 0);
  }, [shoppingCartItems]);

  function selectSetting(setting) {
    history.replace(setting.link);
    setIsSettingsMenuOpen(false);
  }

  function selectCrew(crew, user) {
    setCrew(crew);
    setIsCrewMenuOpen(false);
  }

  // For Tab Header 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLang = (event) => {
    setLang(event.target.value);
    i18next.changeLanguage(event.target.value)
  };

  return (
    <nav>
      <div className="logo">
        <Link to="/">
          <Logo/>
        </Link>
      </div>
      {/* For Mobile and tab start */}
      <div
        className="mobile-item hamburger-menu"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <img src={Hamburger} alt="icon" />
       </div>
      <div className="center-part mobile-item">
       <Link to="/">
          <img src={Mobile_Logo} alt="logo" />
        </Link>
      </div>
      {/* For Mobile and tab  end*/}
      <div className="info-block-wrapper">
        <div className="infoblock">
          <div className="infoblock-column">
            <label>{t('company')}</label>
            <p>{crew.name} ({crew.id})</p>
          </div>

          <Button
            title="Show companies"
            icon={<MoreVertIcon />}
            onClick={() => setIsCrewMenuOpen(!isCrewMenuOpen)}
            style={{ width: 30, height: 30 }}
          />

          {isCrewMenuOpen ? (
            <ClickAwayListener onClickAway={() => setIsCrewMenuOpen(false)}>
              <div className="option-list">
                <SelectMenu optionArray={user.crews} onSelect={selectCrew} />
              </div>
            </ClickAwayListener>
          ) : null}
        </div>
        <Divider className="divider" orientation={"vertical"} flexItem={true} />
        <div className="infoblock">
          <div className="infoblock-column">
            <label>{t('user')}</label>
            <p>{user.fullname}</p>
          </div>
          <Button
            title="Show settings"
            icon={<SettingsIcon />}
            style={{ width: 30, height: 30 }}
            onClick={() => setIsSettingsMenuOpen(!isSettingsMenuOpen)}
          />

          {isSettingsMenuOpen ? (
            <ClickAwayListener onClickAway={() => setIsSettingsMenuOpen(false)}>
              <div className="option-list">
                <SelectMenu
                  search={false}
                  optionArray={settingsOption}
                  onSelect={selectSetting}
                />
              </div>
            </ClickAwayListener>
          ) : null}
        </div>
      </div>
      <div className="right row">

   {/*     
    <Divider className="divider" orientation={"vertical"} flexItem={true} />

    <Box display="flex" alignItems="center">
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Language</InputLabel>
        <Select
          labelId="lang-select-label"
          id="demo-simple-select"
          value={lang}
          label="Lang"
          onChange={changeLang}
        >
          <MenuItem value={'en'}>English</MenuItem>
          <MenuItem value={'de'}>German</MenuItem>
          <MenuItem value={'fr'}>French</MenuItem>
          <MenuItem value={'it'}>Italian</MenuItem>
        </Select>

      </FormControl>
    </Box>
 */}
    <Divider className="divider" orientation={"vertical"} flexItem={true} />

        <Box display="flex" alignItems="center">
          <Button
            icon={
              <Badge
                color="secondary"
                variant="dot"
                invisible={!hasShoppingItems}
              >
                <ShoppingCartIcon
                  color={hasShoppingItems ? "primary" : "disabled"}
                />
              </Badge>
            }
            onClick={() => history.push(`/crew/${crew.id}/cart`)}
            />
          </Box>
          {/* <AppMenu /> */}
          <UserMenu />
        </div>
  
        {/* For Mobile and tab  */}
        <div className="mobile-item">
          <div>
            <div className="search-block">
              <Button
                title="Show companies"
                icon={<img src={Search_Icon} alt="icon" />}
                onClick={() => setIsCrewMenuOpen(!isCrewMenuOpen)}
                style={{ width: 30, height: 30 }}
              />
  
              {isCrewMenuOpen ? (
                <ClickAwayListener onClickAway={() => setIsCrewMenuOpen(false)}>
                  <div className="search-list">
                    <SelectMenu optionArray={user.crews} onSelect={selectCrew} />
                  </div>
                </ClickAwayListener>
              ) : null}
            </div>
          </div>
          <Box display="flex" alignItems="center">
            <Button
              icon={
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={!hasShoppingItems}
                >
                  <svg
                    width={24}
                    height={24}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 7.67V6.7c0-2.25 1.81-4.46 4.06-4.67a4.5 4.5 0 0 1 4.94 4.48v1.38M9 22h6c4.02 0 4.74-1.61 4.95-3.57l.75-6C20.97 9.99 20.27 8 16 8H8c-4.27 0-4.97 1.99-4.7 4.43l.75 6C4.26 20.39 4.98 22 9 22Z"
                      stroke={
                        hasShoppingItems ? "#151B28" : "rgba(0, 0, 0, 0.26)"
                      }
                      strokeWidth={1.5}
                      strokeMiterlimit={10}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.495 12h.01M8.495 12h.008"
                      stroke={
                        hasShoppingItems ? "#151B28" : "rgba(0, 0, 0, 0.26)"
                      }
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Badge>
              }
              onClick={() => history.push(`/crew/${crew.id}/cart`)}
            />
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
            className="profile-box"
          >
            <Tooltip title="Profile">
              <IconButton
                className="m-btn"
                onClick={handleClick}
                size="small"
                sx={{}}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <img src={User_Icon} alt="icon" />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            className="profile-item"
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>
              <ClickAwayListener onClickAway={() => setIsSettingsMenuOpen(false)}>
                <div className="profile-menu">
                  <SelectMenu
                    search={false}
                    optionArray={settingsOption}
                    onSelect={selectSetting}
                  />
                </div>
              </ClickAwayListener>
            </MenuItem>
            <MenuItem>
              <UserMenu /> Logout
            </MenuItem>
          </Menu>
        </div>
      </nav>
    );
  };
  
  export default Header;
  